.cont-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.cont-login form {
    width: 300px;
}

.cont-login form button {
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #0049b6;
    color: white;
    border: none;
    cursor: pointer;
}