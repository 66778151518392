/***SPIN**/
#spin {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    justify-content: center;
    align-items: center;
    background-color: #ffffff9a;
}

@keyframes ldio-nl634peqss {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.ldio-nl634peqss div {
    box-sizing: border-box !important
}

.ldio-nl634peqss>div {
    position: absolute;
    width: 107.88px;
    height: 107.88px;
    top: 8.06px;
    left: 8.06px;
    border-radius: 50%;
    border: 6.2px solid var(--negro);
    border-color: #C5A969 transparent #C5A969 transparent;
    animation: ldio-nl634peqss 2.127659574468085s linear infinite;
}

.ldio-nl634peqss>div:nth-child(2),
.ldio-nl634peqss>div:nth-child(4) {
    width: 93px;
    height: 93px;
    top: 15.5px;
    left: 15.5px;
    animation: ldio-nl634peqss 2.127659574468085s linear infinite reverse;
}

.ldio-nl634peqss>div:nth-child(2) {
    border-color: transparent var(--negro) transparent var(--negro)
}

.ldio-nl634peqss>div:nth-child(3) {
    border-color: transparent
}

.ldio-nl634peqss>div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}

.ldio-nl634peqss>div:nth-child(3) div:before,
.ldio-nl634peqss>div:nth-child(3) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 6.2px;
    height: 6.2px;
    top: -6.2px;
    left: 44.64px;
    background: #C5A969;
    border-radius: 50%;
    box-shadow: 0 101.67999999999999px 0 0 #C5A969;
}

.ldio-nl634peqss>div:nth-child(3) div:after {
    left: -6.2px;
    top: 44.64px;
    box-shadow: 101.67999999999999px 0 0 0 #C5A969;
}

.ldio-nl634peqss>div:nth-child(4) {
    border-color: transparent;
}

.ldio-nl634peqss>div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}

.ldio-nl634peqss>div:nth-child(4) div:before,
.ldio-nl634peqss>div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 6.2px;
    height: 6.2px;
    top: -6.2px;
    left: 37.2px;
    background: var(--negro);
    border-radius: 50%;
    box-shadow: 0 86.8px 0 0 var(--negro);
}

.ldio-nl634peqss>div:nth-child(4) div:after {
    left: -6.2px;
    top: 37.2px;
    box-shadow: 86.8px 0 0 0 var(--negro);
}

.loadingio-spinner-double-ring-yjs29i5x7wd {
    position: relative;
    left: calc(50% - 150px);
    top: calc(50% - 150px);
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*overflow: hidden;*/
}

.ldio-nl634peqss {
    width: 42%;
    height: 50%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-nl634peqss div {
    box-sizing: content-box;
}
/**** MODAL HEADER **/
.cont-login-pop {
    position: absolute;
    top: 80px;
    right: 20px;
    z-index: 201;
}
.titulo-spinner {
    color: var(--negro);
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}
.message-spinner {
    color: var(--negro);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}